import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as firebase from 'services/firebase'
// import withFirebase from 'components/Firebase'
import * as jwt from 'services/jwt'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    loginWithGoogle: firebase.loginWithGoogle,
    // registerWithGoogle: firebase.registerWithGoogle,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
    reset: firebase.doPasswordReset,
    changePassword: firebase.doPasswordUpdate
  },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, email, password)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    // yield history.push('/')
    yield history.push('/loginSuccess')
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
  }
  if (!success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, firstName, lastName, mobile } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, email, password, firstName, lastName, mobile)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    // yield history.push('/')
    yield history.push('/loginSuccess')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  console.log('response from LOAD_CURRENT_ACCOUNT', response)
  if (response) {
    const { 
      id, 
      name, 
      firstName, 
      lastName, nickName, 
      mobile, 
      email, 
      photo, photoBackground, role, roles,
      uid, creditBalance, pointBalance,
      company, company_id, companyRole, 
      position, companyName, companyDesc, companyLogo,
      chapter, chapter_id,

    } = response
    yield put({
      type: actions.SET_STATE,
      payload: {
        id,
        name,
        firstName,
        lastName,
        nickName,
        mobile,
        email,
        // avatar,
        photo, photoBackground, role, roles,
        
        uid, creditBalance, pointBalance,
        company, company_id, companyRole, 
        position, companyName, companyDesc, companyLogo,
        chapter, chapter_id,

        authorized: true,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: actions.SET_STATE,
    payload: {
      id: '',
      name: '',
      firstName: '',
      lastName: '',
      nickName: '',
      role: '',
      roles: '',
      email: '',
      mobile: '',
      // avatar: '',
      photo: '',
      photoBackground: '',
      authorized: false,
      loading: false,

      uid: '',
      credit: 0,
      point: 0,

      company: '',
      company_id: '',
      companyRole: '',

      position: '', 
      companyName: '', 
      companyDesc: '',
      companyLogo: '',

      chapter_id: '',
    },
  })
}

export function* RESET_PASSWORD({ payload }) {
  const { email, password } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].reset, email, password)
  if (success) {
    yield put({
      type: 'user/RESET_PASSWORD',
    })
    // yield history.push('/')
    yield history.push('/auth/login')
    // notification.success({
    //   message: 'Logged In',
    //   description: 'You have successfully logged in!',
    // })
  }
  if (!success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}
export function* CHANGE_PASSWORD({ payload }) {
  const { newPassword } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].changePassword, newPassword)
  if (success) {
    yield put({
      type: 'user/CHANGE_PASSWORD',
    })
    // yield history.push('/')
    // yield history.push('/auth/login')
    // notification.success({
    //   message: 'Logged In',
    //   description: 'You have successfully logged in!',
    // })
  }
  if (!success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* doGetUserProfile() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(firebase.doGetUserProfile)
  if (response) {
    // const { id, email, name, avatar, role } = response
    // yield put({
    //   type: actions.SET_STATE,
    //   payload: {
    //     id,
    //     name,
    //     email,
    //     avatar,
    //     role,
    //     authorized: true,
    //   },
    // })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

function* loginSuccess(success) {
  console.log('loginSuccess', success)
  if (success) {
    yield put({ type: 'user/LOAD_CURRENT_ACCOUNT', })
    yield history.push('/loginSuccess')
    notification.success({ message: 'Logged In', description: 'You have successfully logged in!', })
  } else 
    yield put({ type: actions.SET_STATE, payload: { loading: false, }, })
}
// Start Google //////////////////////////////////////////////////
export function* LOGIN_WITH_GOOGLE({ payload }) { yield call(firebase.loginWithGoogle)}
export function* LOGIN_WITH_FACEBOOK({ payload }) { yield call(firebase.loginWithFacebook)}
export function* LOGIN_WITH_MICROSOFT({ payload }) { yield call(firebase.loginWithMicrosoft)}
export function* LOGIN_WITH_TWITTER({ payload }) { yield call(firebase.loginWithTwitter)}
export function* LOGIN_WITH_APPLE({ payload }) { yield call(firebase.loginWithApple)}

export function* LOGIN_WITH_EMAIL({ payload }) { yield call(firebase.loginWithEmail)}

export function* LOGIN_WITH_MOBILE({ payload }) { yield call(firebase.loginWithMobile,payload.mobile, payload.password)}

export function* LOGIN_WITH_MOBILE_RECAPTCHA({ payload }) { yield call(firebase.loginWithMobile_Recaptcha)}
// export function* LOGIN_WITH_MOBILE_SEND_OTP({ payload }) { 
//   console.log('payload = ', payload)
//   yield call(firebase.loginWithMobile_SendOTP)
// }
export function* LOGIN_WITH_MOBILE_CONFIRM_OTP({ payload }) { yield call(firebase.loginWithMobile_ConfirmOTP,payload.mobile, payload.otp)}
export function* LOGIN_WITH_MOBILE_SEND_OTP({ payload }) { yield call(firebase.loginWithMobile_SendOTP,payload.mobile, '+66')}
// export function* REGISTER_WITH_GOOGLE({ payload }) { yield call(firebase.registerWithGoogle)}
// export function* REGISTER_WITH_FACEBOOK({ payload }) { yield call(firebase.registerWithFacebook)}
// End Google //////////////////////////////////////////////////
export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOGIN_WITH_GOOGLE, LOGIN_WITH_GOOGLE),
    takeEvery(actions.LOGIN_WITH_FACEBOOK, LOGIN_WITH_FACEBOOK),
    takeEvery(actions.LOGIN_WITH_MICROSOFT, LOGIN_WITH_MICROSOFT),
    takeEvery(actions.LOGIN_WITH_TWITTER, LOGIN_WITH_TWITTER),
    takeEvery(actions.LOGIN_WITH_APPLE, LOGIN_WITH_APPLE),
    takeEvery(actions.LOGIN_WITH_EMAIL, LOGIN_WITH_EMAIL),

    takeEvery(actions.LOGIN_WITH_MOBILE, LOGIN_WITH_MOBILE),

    takeEvery(actions.LOGIN_WITH_MOBILE_RECAPTCHA, LOGIN_WITH_MOBILE_RECAPTCHA),
    takeEvery(actions.LOGIN_WITH_MOBILE_SEND_OTP, LOGIN_WITH_MOBILE_SEND_OTP),
    takeEvery(actions.LOGIN_WITH_MOBILE_CONFIRM_OTP, LOGIN_WITH_MOBILE_CONFIRM_OTP),
    // takeEvery(actions.REGISTER_WITH_GOOGLE, REGISTER_WITH_GOOGLE),
    // takeEvery(actions.REGISTER_WITH_FACEBOOK, REGISTER_WITH_FACEBOOK),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
    // doGetUserProfile(),
  ])
}

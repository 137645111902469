import store from 'store'
import actions from './actions'

const STORED_SETTINGS = storedSettings => {
  const cache = {}
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.cache.search.${key}`)
    cache[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return cache
}
// const STORED_SETTINGS = storedSettings => {
//   const cache = {}
//   Object.keys(storedSettings).forEach(key => {
//     const item = store.get(`app.cache.search.${key}`)
//     cache[key] = typeof item !== 'undefined' ? item : storedSettings[key]
//   })
//   return cache
// }

const initialState = {
  
  ...STORED_SETTINGS({
    search: {},
  }),
}

export default function cacheReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      // console.log('action.SET_STATE', action)
      return { ...state, ...action.payload }
    default:
      return state
  }
}

import React from 'react'
import { connect } from 'react-redux'
import styles from './style.module.scss'

const mapStateToProps = ({ user, dispatch }) => ({ user, dispatch })
const SubBar = ({ dispatch, user }) =>{
  return (
    <div className={styles.subbar}>
      <ul className={`${styles.breadcrumbs} mr-4`}>
      </ul>
      
      {user.firstName || ''} {user.lastName || ''} ({user.role || ''})
      {/* <div className={`${styles.amount} mr-3 ml-auto d-none d-sm-flex`}>
        <p className={styles.amountText}>
          Credit
          <span className={styles.amountValue}>{user.creditBalance|| 0}</span>
        </p>
        <div className={styles.amountGraph}>
        </div>
      </div>
      <div className={`${styles.amount} d-none d-sm-flex`}>
        <p className={styles.amountText}>
          Point
          <span className={styles.amountValue}>{user.pointBalance || 0}</span>
        </p>
        <div className={styles.amountGraph}>
        </div>
      </div> */}
    </div>
  )
}

export default connect(mapStateToProps)(SubBar)

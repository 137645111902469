import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'

import master from './master/reducers'
// import search from './search/reducers'
import cache from './cache/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    // master,
    // search,
    cache,
  })

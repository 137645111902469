import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

// const Footer = ({ settings: { isContentMaxWidth, logo, description } }) => {
const Footer = ({ settings: { isContentMaxWidth } }) => {
  return (
    <div
      className={classNames(style.footer, {
        [style.footerFullWidth]: !isContentMaxWidth,
      })}
    >
      <div className={style.inner} style={{fontFamily:"BoonJot" , fontSize:"18px"}}>
        <div className="row">
          <div className="col-sm-8">
            <p>
              <strong>Gentles and Natural Co.,Ltd.</strong><br /><br />

              ที่อยู่ : 555/106 ม.4 ต.มหาสวัสดิ์ อ.บางกรวย จ.นนทบุรี 11130 <br />
              โทร : <a href="tel:024894979">024894979</a><br />
              Email : <a href="mailto:info@gentleandnatural.co.th">info@gentleandnatural.co.th</a><br />
              
            </p>
            {/* <a
              href="https://weget.co.th"
              target="_blank"
              rel="noopener noreferrer"
              className={style.sellpixels}
            >
              Gentles Tots

              <span />
            </a> */}
              
          </div>
          <div className="col-sm-4">
            <div style={{marginTop:"15px"}}>
              <a href="https://www.lazada.co.th/shop/gentle-and-natural/?spm=a2o4m.pdp.seller.1.43d5559bxNsFZD&itemId=1317324711&channelSource=pdp" target="_blank" rel="noopener noreferrer"><img src="etc/logos/social/logo_lazada.webp" style={{marginLeft: "15px", marginTop:"-35px"}} width="48px" alt="social" /></a>
              <a href="https://shopee.co.th/gentles_tots?categoryId=58&itemId=6441237640" target="_blank" rel="noopener noreferrer"><img src="etc/logos/social/logo_shopee.webp" style={{marginLeft: "15px", marginTop:"-35px"}} width="48px" alt="social" /></a>
              {/* <a href="http://line.me/ti/p/~@gentlestots" target="_blank" rel="noopener noreferrer"><img src="etc/logos/social/logo_line.webp" style={{marginLeft: "15px", marginTop:"-40px"}} width="62px" alt="social" /></a> */}
              <a href="http://line.me/ti/p/~@gentlestots" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#00c300"}}><i className="fab fa-3x fa-line"></i></a>
    
              {/* <a href="http://" target="_blank" rel="noopener noreferrer"><i className="fab fa-3x fa-line"></i></a> */}
              <a href="https://www.facebook.com/Gentlestots/" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#3b5998"}}><i className="fab fa-3x fa-facebook-square"></i></a>
              <a href="https://www.youtube.com/channel/UCmBsQZvgcaPkDrnxukxQqkw" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#FF0000"}}><i className="fab fa-3x fa-youtube-square"></i></a>
              <a href="https://www.instagram.com/gentlestots/" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#9b6954"}}><i className="fab fa-3x fa-instagram"></i></a>
              {/* <a href="http://" target="_blank" rel="noopener noreferrer"><i className="fab fa-3x fa-twitter"></i></a>
              <a href="http://" target="_blank" rel="noopener noreferrer"><i className="fab fa-3x fa-linkedin"></i></a> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-4">
            <div className={style.logo}>
                <img
                  src="app/gentlestots/logo/android-chrome-512x512.png"
                  alt="logo"
                  className="pull-right"
                  style={{ display:"block",maxWidth: '50px' }}
                />
              {/* <div className={style.logo__letter}>A</div>
              <div className={style.logo__name}>{logo}</div>
              <div className={style.logo__descr}>{description}</div> */}
            </div>
          </div>
          <div className="col-xs-8">
            <p className="mb-0" style={{paddingTop:"15px"}}>
              Developed By |{' '}
              <a href="https://www.weget.co.th" target="_blank" rel="noopener noreferrer">
                WEGET
              </a>
            </p>
            
            <p className="mb-0">
              Copyright © 2018-{new Date().getFullYear()} Gentles Tots 
              {/* |{' '}
              <a href="/#/web/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a> */}
            </p>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Footer)

import { all, takeEvery, put } from 'redux-saga/effects'
import store from 'store'
import qs from 'qs'
import { history, store as reduxStore } from 'index'
import actions from './actions'

export function* SET_SEARCH({ payload: { docName, docSearch } }) {
  // console.log(`SET_SEARCH ${docName} = `, docSearch)
  yield store.set(`app.cache.search.${docName}`, docSearch)
  // const abc = yield store.get(`app.cache.search.${docName}`)
  // console.log(`abc = `, abc)
  yield put({
    type: 'cache/SET_STATE',
    payload: {
      subset: 'search',
      [docName]: {...docSearch, recentDate: new Date()},
    },
  })
}
// export function* SETUP() {
//   const primaryColor = () => {
//     const color = store.get('app.cache.search') || 'empty'
//     // if (color) {
//     //   reduxStore.dispatch({
//     //     type: 'cache/SET_PRIMARY_COLOR',
//     //     payload: {
//     //       color,
//     //     },
//     //   })
//     // }
//   }
//   yield primaryColor()
// }

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_SEARCH, SET_SEARCH),
  ])
}

const userMap = {
  keys:[
    'id',
    'uid',
    'role',
    'roles',

    'firstName',
    'lastName',
    'nickName',

    // 'email',
    'mobile',

    'photo',
    'photoBackground',

    'company',
    'company_id',
    'position',
    'companyName',
    'companyDesc',
    'companyLogo',
    'companyRole',

    'chapter',
    'chapter_id',
    'creditBalance',
    'pointBalance',
  ]
};
export default userMap 
import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import { FacebookProvider, CustomChat } from 'react-facebook';
import projectConfig from './config'

import Layout from './layouts'

const projectName = "salePage"

const routes = [

  { path: '/projectImageList', Component: lazy(() => import('app/gentlestots/pages/projectImage/list'))},
  { path: '/projectImage/:id', Component: lazy(() => import('app/gentlestots/pages/projectImage/edit'))},

  { path: '/homeSlideList', Component: lazy(() => import('app/gentlestots/pages/homeSlide/list'))},
  { path: '/homeSlide/:id', Component: lazy(() => import('app/gentlestots/pages/homeSlide/edit'))},
  
  // test
  { path: '/testList', Component: lazy(() => import('app/_custom/pages/test/list'))},
  { path: '/test/:id', Component: lazy(() => import('app/_custom/pages/test/edit'))},
  { path: '/test_normal', Component: lazy(() => import('app/_custom/pages/test_normal/list'))},
  // { path: '/test_full', Component: lazy(() => import('app/_custom/pages/test_full/list'))},
  // dynamic
  { path: '/devInit', Component: lazy(() => import('app/_custom/pages/zystem/devInit'))},
  { path: '/masterList', Component: lazy(() => import('app/_custom/pages/master/list'))},
  { path: '/master/:id', Component: lazy(() => import('app/_custom/pages/master/edit'))},
  // user
  // { path: '/userList', Component: lazy(() => import('app/_custom/pages/user/list'))},
  // { path: '/user/:id', Component: lazy(() => import('app/_custom/pages/user/edit'))},
  // Custom
  { path: '/userMyProfile', Component: lazy(() => import('app/_custom/pages/userMyProfile')), exact: true,},

  // member
  // user
  { path: '/userList', Component: lazy(() => import('app/gentlestots/pages/user/list'))},
  { path: '/user/:id', Component: lazy(() => import('app/gentlestots/pages/user/edit'))},

  // home
  { path: '/web/home', Component: lazy(() => import('app/gentlestots/pages/web/home'))},
  { path: '/web/about', Component: lazy(() => import('app/gentlestots/pages/web/about'))},
  { path: '/web/contact', Component: lazy(() => import('app/gentlestots/pages/web/contact'))},
  { path: '/web/product', Component: lazy(() => import('app/gentlestots/pages/web/product'))},
  { path: '/web/whereToBuy', Component: lazy(() => import('app/gentlestots/pages/web/whereToBuy'))},
  { path: '/web/blog', Component: lazy(() => import('app/gentlestots/pages/web/blog'))},

  

  // gen
  { path: '/productList', Component: lazy(() => import('app/gentlestots/pages/product/list'))},
  { path: '/product/:id', Component: lazy(() => import('app/gentlestots/pages/product/edit'))},

  { path: '/productGroupList', Component: lazy(() => import('app/gentlestots/pages/productGroup/list'))},
  { path: '/productGroup/:id', Component: lazy(() => import('app/gentlestots/pages/productGroup/edit'))},

  { path: '/blogList', Component: lazy(() => import('app/gentlestots/pages/blog/list'))},
  { path: '/blog/:id', Component: lazy(() => import('app/gentlestots/pages/blog/edit'))},

  { path: '/blogGroupList', Component: lazy(() => import('app/gentlestots/pages/blogGroup/list'))},
  { path: '/blogGroup/:id', Component: lazy(() => import('app/gentlestots/pages/blogGroup/edit'))},

  { path: '/contactRegisterList', Component: lazy(() => import('app/gentlestots/pages/contactRegister/list'))},
  { path: '/contactRegister/:id', Component: lazy(() => import('app/gentlestots/pages/contactRegister/edit'))},




  // Auth Pages
  { path: '/auth/userBan', Component: lazy(() => import('pages/auth/userBan')), exact: true, }, 
  { path: '/auth/login', Component: lazy(() => import('pages/auth/login')), exact: true, }, 
  { path: '/auth/forgot-password', Component: lazy(() => import('pages/auth/forgot-password')), exact: true, }, 
  { path: '/auth/register', Component: lazy(() => import('pages/auth/register')), exact: true, },
  { path: '/auth/lockscreen', Component: lazy(() => import('pages/auth/lockscreen')), exact: true, },
  { path: '/auth/404', Component: lazy(() => import('pages/auth/404')), exact: true, },
  { path: '/auth/500', Component: lazy(() => import('pages/auth/500')), exact: true, },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      {
        projectConfig && projectConfig.facebook && projectConfig.facebook.pageId && projectConfig.facebook.appId && (
          // <FacebookProvider appId="474812183840866" chatSupport>
          //   <CustomChat pageId="391079311372123" minimized={false}/>
          // </FacebookProvider> 
          <FacebookProvider appId={projectConfig.facebook.appId} chatSupport>
            <CustomChat pageId={projectConfig.facebook.pageId} minimized={false}/>
          </FacebookProvider> 
        )
      }
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                {/* {console.log(location)} */}
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* <Route exact path="/" render={() => <Redirect to="/userMyProfile" />} /> */}
                    {/* <Route exact path="/loginSuccess" render={() => <Redirect to="/userMyProfile" />} /> */}
                    <Route exact path="/" render={() => <Redirect to="/web/home" />} />
                    <Route exact path="/loginSuccess" render={() => <Redirect to="/blogList" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={(props) => {
                          return (
                            <div className={routerAnimation} >
                              <Suspense fallback={null} >
                                {/* props.match.params = {JSON.stringify(props.match.params)} */}
                                <Component props={props} />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}
export default connect(mapStateToProps)(Router)
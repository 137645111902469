import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'topBar.issuesHistory': 'Issues History 1',
  'topBar.typeToSearch': 'Start typing to search...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',

  // 'FEATURE_1_TOPIC': 'PRODUCTS FROM NATURE',
  // 'FEATURE_1_DESC': 'All products are organic products that make from natural to ensure you about the safety without additives and also reduce the chance of allergic reactions for your little one in all cases.',

  "FONT" : "font-billy",
  "MENU" : {
    "HOME":"Home",
    "PRODUCT" : "Product",
    "NEWS" : "News",
    "BLOGS" : "Blogs",
    "WHERETOBUY" : "Where To Buy",
    "ABOUT" : "About",
    "CONTACT" : "Contact"
  },
  "PRICE":"Price",
  "BAHT":"Baht",
  "FEATURE_1_TOPIC" : "Products from Nature",
  "FEATURE_1_DESC" : "All products are organic products that make from natural to ensure you about the safety without additives and also reduce the chance of allergic reactions for your little one in all cases.",
  "FEATURE_2_TOPIC" : "Certified Safety Test",
  "FEATURE_2_DESC" : "All Gentles Tots products have been certified both components and products by domestic and international institutions for the maximum confidence of every mother.",
  "FEATURE_3_TOPIC" : "Quality Assurance ",
  "FEATURE_3_DESC" : "Gentles Tots are products designed and developed specially for Baby & Kids. We have mainly considered quality as the first priority. Therefore, every mother can be assured that their kids will use the best products under the Gentles Tots brand.",
  "FEATURE_4_TOPIC" : "Everyday Better Life",
  "FEATURE_4_DESC" : "Gentles Tots has strived to provide better care for baby and kid of all ages in every day. With our certified organic and natural products that have been invented and improved to the superior level for a better quality of life for both mothers and little one in every family.",
  "ABOUT_US" : "Gentles Tots began with a mother withtwo children who are sensitive allergy-prone skin then she sought a product that is natural, safe and free of chemicals that cause allergies by using natural extracts that are equally effective. However, various domestic products tend to contain chemicals for being not too expensive. Imported products are quite rare, expensive and do not purely contain a natural extract that is high-quality and sufficiently effective, even it is a natural extract. Gentles Tots was established by researching hundreds of domestic and international products to find the perfect formula. along with consulting, researching and developing the exclusive formula from both domestic and international experts. Eventually, our products have been certified for confidence in your mother's.",
  "ABOUT_US1" : "Gentles Tots began with a mother withtwo children who are sensitive allergy-prone skin then she sought a product that is natural, safe and free of chemicals that cause allergies by using natural extracts that are equally effective. However, various domestic products tend to contain chemicals for being not too expensive. Imported products are quite rare, expensive and do not purely contain a natural extract that is high-quality and sufficiently effective, even it is a natural extract. ",
  "ABOUT_US2" : "Gentles Tots was established by researching hundreds of domestic and international products to find the perfect formula. along with consulting, researching and developing the exclusive formula from both domestic and international experts. Eventually, our products have been certified for confidence in your mother's.",



  "PRODUCT_1_1_TOPIC" : "Baby's 1st organic toothgel (Apple Flavor)",
  "PRODUCT_1_1_DESC" : "Age 3+ months size 40 g.",
  "PRODUCT_1_1_PRICE": "295",

  "PRODUCT_1_2_TOPIC" : "Kids' organic toothgel (Grape&Berry Flavor)",
  "PRODUCT_1_2_DESC" : "Age 1+ Years size 50 g.",
  "PRODUCT_1_2_PRICE": "325",

  "PRODUCT_1_3_TOPIC" : "Organic Oral Spray foy Kids (Apple Flavor)",
  "PRODUCT_1_3_DESC" : "Age 1+ Years",
  "PRODUCT_1_3_PRICE": "269",

  "PRODUCT_1_4_TOPIC" : "Organic Oral Spray foy Kids (Strawberry Custard Flavor)",
  "PRODUCT_1_4_DESC" : "Age 1+ Years",
  "PRODUCT_1_4_PRICE": "269",



  "PRODUCT_2_1_TOPIC" : "Flossy - Kids Dental Floss (60 Pcs./Box)",
  "PRODUCT_2_1_DESC" : "6 Flavors (Lemon, Orange, Strawberry, Grape, Melon and Peach) Imported from Japan",
  "PRODUCT_2_1_PRICE": "329",

  "PRODUCT_2_2_TOPIC" : "Flossy - Kids Dental Floss (30 Pcs./Pack)",
  "PRODUCT_2_2_DESC" : "6 Flavors (Banana, Orange, Strawberry, Grape, Apple and Lemon) Imported from Japan",
  "PRODUCT_2_2_PRICE": "219",

  "PRODUCT_2_3_TOPIC" : "360-Degree Toothbrush",
  "PRODUCT_2_3_DESC" : "new born - 3 years old (Yellow/White) Imported from Japan",
  "PRODUCT_2_3_PRICE": "139",

  "PRODUCT_2_4_TOPIC" : "360-Degree \"PLUS\" Toothbrush with protector baby's mouth and throat.",
  "PRODUCT_2_4_DESC" : "new born - 3 years old (Yellow/White) Imported from Japan",
  "PRODUCT_2_4_PRICE": "189",

  "PRODUCT_2_5_TOPIC" : "360-Degree \"HABIT\" Toothbrush with silicone handle and protector baby's mouth and throat.",
  "PRODUCT_2_5_DESC" : "new born - 3 years old (Yellow/Blue) Imported from Japan",
  "PRODUCT_2_5_PRICE": "229",


  WHERE_TO_BUY_1_TOPIC: 'Central Food Hall Branch / Tops Supermarket Branch / Tops Super Store Branch in Thailand',
  WHERE_TO_BUY_2_TOPIC: 'Baby Shop in Thailand',



  "BUY":"Buy",
  "":""
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}

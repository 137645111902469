// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5vA13nS5eJedIEV2aPSvCO_Edk9fN7do",
  authDomain: "gentlestots.firebaseapp.com",
  databaseURL: "https://gentlestots.firebaseio.com",
  projectId: "gentlestots",
  storageBucket: "gentlestots.appspot.com",
  messagingSenderId: "976366824950",
  appId: "1:976366824950:web:58a881329ddf4639376bd7"
};
export default firebaseConfig 

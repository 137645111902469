import firebase from 'firebase/compat/app'
import { call, put } from "redux-saga/effects";
import { notification } from 'antd'
import { connect } from 'react-redux'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/functions'
import { history } from 'index'
// import { userLog } from 'config/dbUtils'
// import { userLog } from '../../config/dbUtils'
import firebaseConfig from './firebase-config'
import userMap from './userMap'
import userMapDefault from './userMapDefault'

// fb.database.ServerValue.TIMESTAMP
// fb.firestore.FieldValue.serverTimestamp()

firebase.initializeApp(firebaseConfig)
export const fb = firebase;
export const auth = firebase.auth()
export const db = firebase.firestore()
export const rdb = firebase.database()
export const storage = firebase.storage()
// export const functions = firebase.functions().region('asia-southeast2')

export const userLog = (log, optionData) => {
  const {uid, email, type, action, messages} = log
  const batch = db.batch()
  if(optionData){
    if(Array.isArray(optionData)){
      optionData.forEach(item => {
        const { ref, data } = item
        if(ref && data)
          batch.set( ref, data, { merge: true})
      })
    }else{
      const { ref, data } = optionData
      if(ref && data)
        batch.set( ref, data, { merge: true})
    }
    
  }
  batch.set(
    db.collection("_userLog").doc(email),
    {
      _email: email, 
      _uid: uid || '',
      updateDate: fb.firestore.FieldValue.serverTimestamp(),
      [action || '_']: fb.firestore.FieldValue.increment(1),
    }, 
    { merge: true}
  )
  batch.set(
    db.collection("_userLog").doc(email).collection('log').doc(),
    {
      // _uid: uid,
      // _email: email, 
      createDate: fb.firestore.FieldValue.serverTimestamp(),
      type: type || '',
      action: action || '_', 
      messages: messages || ''
      // processResult: 'success', 
      // messages: 'get my user profile'
    }
  )
  batch.commit()
}
export async function login(email, password) {
  return auth
    .signInWithEmailAndPassword(email, password)
    .then((response) => {
      console.log("signed = ", response)
      userLog(
        {uid: response.user.uid, email: response.user.email, type: 'info', action: 'signIn'}, 
      )
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}
export async function register(email, password, firstName, lastName, mobile) {
  return registerWithRole(email, password, firstName, lastName, mobile, "member")
}
export async function registerWithRole(email, password, firstName, lastName, mobile, role) {
  const provider = 'email'
  const role_id = '2'
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      const userData = response.user
      console.log(`user register with ${provider}`, userData)
      if(userData && userData.uid) {
        registerUserProfile(
          provider,
          role || 'member', 
          role_id, 
          userData.uid, 
          userData.email || '', 
          firstName || '', 
          lastName || '', 
          '', 
          mobile || '', 
          '',
        )
      }
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}
export async function currentAccount(action, nextFunction) {
  let userLoaded = false
  function getCurrentUser(authUser) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(authUser.currentUser)
      }
      const unsubscribe = authUser.onAuthStateChanged(userState => {
        userLoaded = true
        unsubscribe()
        const getUserData = async () => {
          console.log("_____ loadProfile ____________________")
          // console.log("userState = ",userState)
          if (userState && userState.uid) {
            console.log("userState.uid = ",userState.uid)
            const userAuthSS = await firebase.firestore().collection('userAuth').where("uid", "==", userState.uid).get()
            const userAuthList = userAuthSS.docs.map(doc => ({...doc.data(), id: doc.id}))
            const userAuth = userAuthList.length > 0 ? userAuthList[0] : null
            if(userAuth)
              console.log("userAuth = ", userAuth)

            let user = null
            if(userAuth && userAuth != null){
              // const userSS = await firebase.firestore()
              // .collection('user').where("id", "==", userAuth.user_id).where("status", "==", "active").get()
              // const userList = userSS.docs.map(doc => ({...doc.data(), id: doc.id}))
              // user = userList.length > 0 ? userList[0] : null
              await firebase.firestore()
              .collection('user').doc(userAuth.user_id).get().then((snapshort) => {
                if(snapshort.data()){
                  user = { id: snapshort.id, ...snapshort.data() }  
                  if(user.status === 'inActive'){
                    history.push('/auth/userBan')
                  }
                }
              })
            } else {
              const userSS = await firebase.firestore().collection('user').where("uid", "==", userState.uid)
              // .where("status", "==", "active")
              .get()
              const userList = userSS.docs.map(doc => ({...doc.data(), id: doc.id}))
              user = userList.length > 0 ? userList[0] : null
            }

            if(process.env.NODE_ENV === 'development')
              console.log("current = ", user)
            if(user === null || user === undefined){
              console.log("can't load user profile. please try to login again.")
              // history.push('/auth/userBan')
            }

            if(user){
              const userMapList = userMap && userMap.keys && userMap.keys.length > 0 ? userMap : userMapDefault
              const userMapResult = {}
              userMapList.keys.forEach(key => {
                // console.log(key, user[key])
                Object.assign(userMapResult, {[key]: user[key] || ''})
              })
              const mergedUser = { ... userState, ...userMapResult }
              // const mergedUser = Object.assign(userState, userMapResult)
              // const mergedUser = Object.assign(userState, {
              //   roles: user.roles || '',
              //   // id: user.uid,
              //   // _uid: user.uid,
              //   id: user.id,
              //   firstName: user.firstName || '',
              //   lastName: user.lastName || '',
              //   nickName: user.nickName || '',
              //   mobile: user.mobile || '',
              //   role: user.role,
              //   // avatar: user.photoUrl,
              //   photo: user.photo,
              //   photoBackground: user.photoBackground,

              //   company: user.company,
              //   company_id: user.company_id,

              //   position: user.position,
              //   companyName: user.companyName,
              //   companyDesc: user.companyDesc,
              //   companyLogo: user.companyLogo,

              //   companyRole: user.companyRole,

              //   chapter: user.chapter,
              //   chapter_id: user.chapter_id,

              //   creditBalance: user.creditBalance,
              //   pointBalance: user.pointBalance,
              // })
              // userLog(
              //   {uid: user.uid, email: user.email, type: 'info', action: 'currentAccount'}, 
              // )
              return mergedUser
            }
          }
          // console.log("added userActivityLog")
          // db.collection("userActivityLog").add({user: user.id, email: user.email, active: 'getProfile', messages: 'get my user profile'})
          console.log("______________________________________")
          return userState
        }
        resolve(getUserData())
      }, reject)
    })
  }
  return getCurrentUser(auth)
}
export async function logout() {
  return auth.signOut().then(() => true)
}
export async function doGetUserProfile() {
  const docRef = db.collection("user").doc("c");
  docRef.get().then(function func(doc) {
      if (doc.exists) {
          console.log("Document data:", doc.data());
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }
  }).catch(function (error) {
      console.log("Error getting document:", error);
  });
}

// Auth ########################################################################

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password)

// Sign Out
export const doSignOut = () => {
  auth.signOut()
  history.push('/auth/login')
}

// // Password Reset
export const doPasswordReset = email =>
  // console.log("email for reset = ",email)
  auth
    .sendPasswordResetEmail(email)
    .then(function(response) {
      // Password reset email sent.
      console.log("Reset email = ", email)
      userLog(
        {uid: response.user.uid, email: response.user.email, type: 'info', action: 'resetPassword'}, 
      )
      notification.info({
        message: "Reset Password",
        description: "Please check inbox / trash in your email for reset your password.",
      })
    })
    .catch(function(error) {
      // Error occurred. Inspect error.code.
      console.log("Error = ", error)
      notification.warning({
        message: error.code,
        description: error.message,
      })
    });

// Password Change
export const doPasswordUpdate = password => {
  if(password){
    fb.auth().onAuthStateChanged(function(user) {
      if(user){
        // auth.currentUser
        user.updatePassword(password)
        .then(function(response) {
          // Password reset email sent.
          // console.log("Reset email = ", email)
          userLog(
            {uid: response.user.uid, email: response.user.email, type: 'info', action: 'resetPassword', messages: password}, 
          )
          notification.info({
            message: "Change Password",
            description: "Change your password success.",
          })
        })
        .catch(function(error) {
          // Error occurred. Inspect error.code.
          console.log("error = ", error)
          notification.warning({
            message: error.code,
            description: error.message,
          })
        })
      } else {
        // No user is signed in.
      }
    })
  }
}

// //Email Update
// export const doEmailUpdate = email =>
//   auth.currentUser.updateEmail(email).then(
//     db
//       .collection("user")
//       .doc("user3") //retrieve user name from fb
//       .update({
//         email: email
//       })
//   );

// //Update nick name
// export const doNickNameUpdate = nickname =>
//   db
//     .collection("user")
//     .doc("c")
//     .update({
//       name: nickname
//     });

// export const doBioUpdate = newBio =>
//   db
//     .collection("user")
//     .doc(localStorage.getItem("username"))
//     .update({
//       bio: newBio
//     });




// Start user / userAuth (create / load) //////////////////////////////////////////////////
const loadProfile = (result) => {
  const userData = result.user
  console.log('loadProfile = ', userData.email)
  notification.success({ message: 'Logged In', description: 'You have successfully logged in!' })
  history.push('/web/loadProfile')
}
const oAuthLoadConfig = (providerName) => {
  let provider = null
  if(providerName === 'Google'){
    provider = new fb.auth.GoogleAuthProvider()
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
    provider.setCustomParameters({
      'login_hint': 'user@example.com',
      'display': 'popup',
    })
  } else if(providerName === 'Facebook'){
    provider = new fb.auth.FacebookAuthProvider()
    // provider.addScope('user_birthday')
    provider.addScope('email')
    provider.setCustomParameters({
      'display': 'popup'
    })
  } else if(providerName === 'Microsoft'){
    provider = new fb.auth.OAuthProvider('microsoft.com')
    provider.addScope('email')
    provider.addScope('mail.read');
    provider.addScope('calendars.read');
    provider.setCustomParameters({
      prompt: 'consent',
      login_hint: 'user@firstadd.onmicrosoft.com',
      // tenant: '93f1d302-32b4-4a8e-affc-31efac830a26',
    })
  } else if(providerName === 'Apple'){
    provider = new fb.auth.OAuthProvider('apple.com')
    provider.addScope('email');
    provider.addScope('name');
  } else if(providerName === 'Twitter'){
    provider = new fb.auth.TwitterAuthProvider()
    // provider.addScope('email');
    // provider.addScope('profile');
    provider.addScope('users.read');
    provider.setCustomParameters({
      'lang': 'en'
    })
  } 
  
  return provider
}
const authWithSocial = (providerName) => {
  console.log("authWithSocial", providerName)
  const provider = oAuthLoadConfig(providerName)
  fb.auth().languageCode = 'th'
  fb.auth()
    .signInWithPopup(provider)
    .then((result) => {
      console.log(`Auth with ${providerName}`, result)
      checkUserProfileExistOrCreate(providerName, result, 'member', '2') 
    }).catch((error) => {
      notification.warning({ message: error.code, description: error.message, })
    })
}
const checkUserProfileExistOrCreate = async (providerName, result, role, role_id) => {
  const userState = result.user
  console.log("checkUserProfileExistOrCreate result = ", result)
  console.log("userState.uid = ", userState.uid)
  if(userState && userState.uid){
    const userAuthSS = await firebase.firestore().collection('userAuth').where("uid", "==", userState.uid).get()
    const userAuthList = userAuthSS.docs.map(doc => ({...doc.data(), id: doc.id}))
    const userAuth = userAuthList.length > 0 ? userAuthList[0] : null
    console.log(`userAuth / ${userAuthList.length} = `, userAuth)

    let user = null
    if(userAuth && userAuth != null){
      // const userSS = await firebase.firestore()
      // .collection('user').where("id", "==", userAuth.user_id).where("status", "==", "active").get()
      // const userList = userSS.docs.map(doc => ({...doc.data(), id: doc.id}))
      // user = userList.length > 0 ? userList[0] : null
      await firebase.firestore()
      .collection('user').doc(userAuth.user_id).get().then((snapshort) => {
        if(snapshort.data()){
          user = { id: snapshort.id, ...snapshort.data() }  
          if(user.status === 'inActive'){
            history.push('/auth/userBan')
          }
        }
        
      })
    } else {
      const userSS = await firebase.firestore().collection('user').where("uid", "==", userState.uid)
      // .where("status", "==", "active")
      .get()
      const userList = userSS.docs.map(doc => ({...doc.data(), id: doc.id}))
      user = userList.length > 0 ? userList[0] : null
    }
    console.log(`user = `, user)
    if(user === null || user === undefined){
      console.log('USER NOT FOUND / CREATE AN NEW PROFILE')
      // registerUserProfileWithOAuth(providerName, result, 'member', '2')
      if(providerName === 'Mobile')
        registerUserProfileWithMobile(providerName, result, role, role, role_id)
      else
        registerUserProfileWithOAuth(providerName, result, role, role, role_id)
    }
    loadProfile(result)
  }
}
// export async function loadCreateUserProfile(provider, role, role_id, uid, email, firstName, lastName, nickname, mobile, photo) {
//   db.collection("_stat_").doc(role).set({ register: fb.firestore.FieldValue.increment(1) }, { merge: true })
//   userLog({uid, email, mobile, type: 'info', action: `Sign Up with ${provider}`}, [])
//   const addUserAuth = (user_id) => {
//     db.collection("userAuth").add({
//       uid,
//       uidProvider: provider,
//       user_id,
//       firstName,
//       lastName,
//       email,
//       mobile, 
//       createDate: fb.firestore.FieldValue.serverTimestamp(),
//       createFrom: provider,
//       status: 'active',
//       status_id: '1',
//     }).then(function func(data){
//       console.log("userAuth.id = ",data.id)
//       // openNotificationWithIcon('success','Save Complete','' )
//     })
//     .catch(function func(err){
//       console.log("err = ",err)
//       // openNotificationWithIcon('error','Save Error','Please try agian.' )
//     })
//   }
//   db.collection("user").add({
//     uid,
//     uidProvider: provider,
//     role,
//     roles: [role],
//     role_id,
//     roles_id: [role_id],
//     firstName, 
//     lastName, 
//     nickname,
//     email,
//     mobile, 
//     photo,
//     createDate: fb.firestore.FieldValue.serverTimestamp(),
//     createFrom: provider,
//     status: 'active',
//     status_id: '1',
//   }).then(function func(data){
//     console.log("user.id = ",data.id)
//     addUserAuth(data.id)
//     db.collection("user").doc(data.id).update({id: data.id})
//     // openNotificationWithIcon('success','Save Complete','' )
//   })
//   .catch(function func(err){
//     console.log("err = ",err)
//     // openNotificationWithIcon('error','Save Error','Please try agian.' )
//   })
// }
const registerUserProfileWithMobile = (provider, result, role, role_id) => {
  const userData = result.user
  console.log(`user register with ${provider} / uid = ${userData.uid}`, userData)
  if(userData && userData.uid) {
    registerUserProfile(
      provider, 
      role || 'member', 
      role_id || '2', 
      userData.uid, 
      userData.email || '', 
      userData.firstName || '', 
      userData.lastName || '', 
      userData.nickName || '', 
      userData.phoneNumber || '', 
      userData.photoURL || '',
    )
  }
  notification.success({ message: 'Logged In', description: 'You have successfully logged in!' })
  history.push('/web/loadProfile')
}
const registerUserProfileWithOAuth = (provider, result, role, role_id) => {
  const userData = result.user
  console.log(`user register with ${provider}`, userData)
  if(userData && userData.uid) {
    registerUserProfile(
      provider, 
      role || 'member', 
      role_id || '2', 
      userData.uid, 
      userData.email || '', 
      userData.displayName.split(' ')[0], 
      userData.displayName.split(' ')[1], 
      userData.nickName || '', 
      userData.phoneNumber || '', 
      userData.photoURL || '',
    )
  }
  notification.success({ message: 'Logged In', description: 'You have successfully logged in!' })
  history.push('/web/loadProfile')
}
export async function registerUserProfile(provider, role, role_id, uid, email, firstName, lastName, nickname, mobile, photo) {
  console.log('registerUserProfile uid = ',uid)
  // db.collection("_stat_").doc(role).set({ register: fb.firestore.FieldValue.increment(1) }, { merge: true }).then(function (result) {console.log(`_stats_ ${role} increment 1 `)})
  // userLog({uid: uid , email: email || '', mobile: mobile || '', type: 'info', action: `Sign Up with ${provider}`}, [])
  const addUserAuth = (user_id) => {
    db.collection("userAuth").add({
      uid, uidProvider: provider,
      user_id, 
      firstName: firstName || '', 
      lastName: lastName || '',
      email: email || '', 
      mobile: mobile || '', 
      createDate: fb.firestore.FieldValue.serverTimestamp(),
      createFrom: provider,
      status: 'active',
      status_id: '1',
    }).then(function func(data){
      console.log("new user added / userAuth.id = ",data.id)
      // openNotificationWithIcon('success','Save Complete','' )
    })
    .catch(function func(err){
      console.log("err = ",err)
      // openNotificationWithIcon('error','Save Error','Please try agian.' )
    })
  }
  db.collection("user").add({
    uid, uidProvider: provider, mobileVerify: provider === 'Mobile' ? true : false,
    role, roles: [role], role_id, roles_id: [role_id],
    photo: photo || '',
    firstName: firstName || '', 
    lastName: lastName || '',
    nickname: nickname || '',
    email: email || '', 
    mobile: mobile || '', 
    createDate: fb.firestore.FieldValue.serverTimestamp(),
    createFrom: provider,
    status: 'active',
    status_id: '1',
  }).then(function func(data){
    console.log("new user added / user.id = ",data.id)
    addUserAuth(data.id)
    db.collection("user").doc(data.id).update({id: data.id})
    // openNotificationWithIcon('success','Save Complete','' )
  })
  .catch(function func(err){
    console.log("err = ",err)
    // openNotificationWithIcon('error','Save Error','Please try agian.' )
  })
}
export async function deleteAllUserAuth(user_id) {
  if(user_id && user_id != null){
    const userAuthSS = await fb.firestore().collection('userAuth').where("user_id", "==", user_id).get()
    const userAuthList = userAuthSS.docs.map(doc => ({...doc.data(), id: doc.id}))
    userAuthList.forEach(item => {
      db.collection('userAuth').doc(item.id).delete()
      .then(function(){console.log(`Delete auth ${item.uidProvider} uid=${item.uid}`)})
    })
  }
}
export async function deleteUserAuth(user_id, uid) {
  if(user_id && user_id != null){
    const userAuthSS = await fb.firestore().collection('userAuth').where("user_id", "==", user_id).get()
    const userAuthList = userAuthSS.docs.map(doc => ({...doc.data(), id: doc.id}))
    userAuthList.forEach(item => {
      if(uid && uid != null)
        db.collection('userAuth').doc(item.id).delete()
        .then(function(){console.log(`Delete auth ${item.uidProvider} uid=${item.uid}`)})
    })
  }
}
export async function deleteMyAccount(user_id) {
  if(user_id && user_id != null){
    var user = firebase.auth().currentUser
    const uid = user.uid
    user.delete().then(function() {
      console.log(`Deleted user_id = ${user_id} / uid = ${uid} / ${user.email || user.mobile}/ ${user.firstName} ${user.lastName}`)
      deleteUserProfileAndAuth(user_id)
      history.push('/auth/login')
    }).catch(function(err) {
      console.log("err = ",err)
    });
  }
}
export async function deleteUserProfileAndAuth(user_id) {
  if(user_id && user_id != null){
    fb.firestore().collection('user').doc(user_id).get()
    .then((snapshort) => {
      if(snapshort.data()){
        const item = { id: snapshort.id, ...snapshort.data() }  
        fb.firestore().collection('user').doc(user_id).delete()
        .then(function(){
          console.log(`Deleted user_id=${user_id} e: ${item.email} / m: ${item.mobile}/ ${item.uidProvider} / ${item.firstName} ${item.lastName}`)
          deleteAllUserAuth(user_id)
        }) 
      }
    }).catch(function func(err) {
      console.log("err = ",err); 
    })
  }
}
// Start Email //////////////////////////////////////////////////
export function loginWithEmail(email, password) {
  return auth.signInWithEmailAndPassword(email, password).then((response) => {
    console.log("signed = ", response)
    loadProfile(response)
    return true
  })
  .catch(error => { 
    notification.warning({ message: error.code, description: error.message,})
  })
}
export async function registerWithEmail(email, password, firstName, lastName, mobile, role) {
  const provider = 'email'
  const role_id = '2'
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      const userData = response.user
      console.log(`user register with ${provider}`, userData)
      if(userData && userData.uid) {
        registerUserProfile(
          provider,
          role || 'member', 
          role_id, 
          userData.uid, 
          userData.email || '', 
          firstName || '', 
          lastName || '', 
          '', 
          mobile || '', 
          '',
        )
        
      }
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}
// Start Google //////////////////////////////////////////////////
// export function registerWithGoogle(){ 
  //   const next = (result) => { registerUserProfileWithOAuth('Google', result, 'member', '2') }; 
  //   return authWithSocial('Google',next) 
  // }
  // Start Facebook //////////////////////////////////////////////////
export function loginWithGoogle(){ authWithSocial('Google') }
export function loginWithFacebook(){ authWithSocial('Facebook') }
export function loginWithMicrosoft(){ authWithSocial('Microsoft') }
export function loginWithTwitter(){ authWithSocial('Twitter') }
export function loginWithApple(){ authWithSocial('Apple') }
// Start Mobile //////////////////////////////////////////////////
export const loginWithMobile_Recaptcha = () => {
  window.recaptchaVerifier = new fb.auth.RecaptchaVerifier('sign-in-button', {
    'size': 'invisible',
    'callback': (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      // onSignInMobile(values)
      console.log("Recaptca varified")
    },
    defaultCountry: "TH"
  });
}
export const loginWithMobile_SendOTP = (mobile) => {
  if(mobile && mobile !== ''){
    const phoneNumber = "+66" + mobile
    // console.log('phoneNumber',phoneNumber)
    const appVerifier = window.recaptchaVerifier;
    fb.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        console.log(`Auth with Mobile (${phoneNumber}) OTP has sended `, confirmationResult)
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        // ...
        console.log("SMS can't sent", error);
      })
  }
}
export const loginWithMobile_ConfirmOTP = (mobile, otp) => {
  console.log('otp',otp)
  if(otp){
    window.confirmationResult.confirm(otp).then((result) => {
      // User signed in successfully.
      var credential = result.credential;
  
      // var userData = result.user;
      // var accessToken = credential.accessToken;
      // console.log(accessToken, userData)
      console.log(`Auth with Mobile (${mobile} / ${otp}) Verified`, result)
      checkUserProfileExistOrCreate('Mobile', result, 'member', '2') 
      // ...
    }).catch((error) => {
      console.log('error = ', error)
      // User couldn't sign in (bad verification code?)
      // ...
    })
  }
}
export function loginWithMobile(email, password) {
  return auth.signInWithEmailAndPassword(email, password).then((response) => {
    console.log("signed = ", response)
    loadProfile(response)
    return true
  })
  .catch(error => { 
    notification.warning({ message: error.code, description: error.message,})
  })
}
// End Mobile //////////////////////////////////////////////////



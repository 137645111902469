const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  CHANGE_PASSWORD: 'user/CHANGE_PASSWORD',
  LOGIN_WITH_GOOGLE: 'user/LOGIN_WITH_GOOGLE',
  LOGIN_WITH_FACEBOOK: 'user/LOGIN_WITH_FACEBOOK',
  LOGIN_WITH_MICROSOFT: 'user/LOGIN_WITH_MICROSOFT',
  LOGIN_WITH_TWITTER: 'user/LOGIN_WITH_TWITTER',
  LOGIN_WITH_APPLE: 'user/LOGIN_WITH_APPLE',

  LOGIN_WITH_EMAIL: 'user/LOGIN_WITH_EMAIL',

  LOGIN_WITH_MOBILE: 'user/LOGIN_WITH_MOBILE',

  LOGIN_WITH_MOBILE_RECAPTCHA: 'user/LOGIN_WITH_MOBILE_RECAPTCHA',
  LOGIN_WITH_MOBILE_SEND_OTP: 'user/LOGIN_WITH_MOBILE_SEND_OTP',
  LOGIN_WITH_MOBILE_CONFIRM_OTP: 'user/LOGIN_WITH_MOBILE_CONFIRM_OTP',



  REGISTER_WITH_GOOGLE: 'user/REGISTER_WITH_GOOGLE',
  REGISTER_WITH_FACEBOOK: 'user/REGISTER_WITH_FACEBOOK',
}

export default actions

import { all } from 'redux-saga/effects'

import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'

import master from './master/sagas'
// import search from './search/sagas'
import cache from './cache/sagas'

export default function* rootSaga() {
  yield all([
    user(), 
    menu(), 
    settings(),
    // master(),
    // search(),
    cache(),
  ])
}

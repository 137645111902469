const projectConfig = {
    frontOffice: 'active', // active / inActive

    facebook : {
      appId: '474812183840866', // default ball facebook dev app name = React Web Chat Integration

      // config at https://www.facebook.com/wegetcompany/settings/?tab=messaging
      // pageId: '391079311372123', // weget
      // pageId: '306725926676739', // upbrand
      pageId: '2003917883268443', // gentlestots
      
    },
    googleAnalytic : {
      // tracking_id : 'UA-120432092-1', // example
      // tracking_id : 'UA-120432092-1',
    }
  }
  export default projectConfig 